<template>
    <div id="transaction-history" class="negative-margin-right">

        <status class="mt-2" :class="{'mr-6' : showAside}" @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
        <status class="mt-2" :class="{'mr-6' : showAside}" @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>

        <hb-notification
          v-model="notification"
          v-if="notification"
          class="ma-0 pa-0 mt-2"
          :class="{'pr-6' : showAside}"
          :type="notification_type"
        >
            {{ notification_message }}
        </hb-notification>

        <div>
            <v-row class="ma-0 px-0 py-0">
                <v-col cols="12" class="px-0 d-flex align-center" :class="{'flex-wrap gap-2' : $vuetify.breakpoint.width < 700}">
                    <div style="width:125px;">
                        <space-selection-dropdown  :setSelection="false" :mainViewOptions="mainViewOptions" :contactLeases="contactLeases" @spaceSelectionChanged="filterDataOnSelectedSpace">
                        </space-selection-dropdown>
                 
                </div>
                <div class="pl-2">
                  <v-menu v-model="showFilters" :close-on-content-click="false" offset-y content-class="hb-elevation-large">
                      <template v-slot:activator="{ on }">
                          <div v-on="on" class="d-flex align-center pl-4 pr-2 select-filter" :class="{ 'download-center-filters-active' : showFilters }">
                            <span class="font-weight-regular hb-default-font-size">{{setFilterText.length > 0 ?  setFilterText : 'Filter'}}</span>
                            <v-spacer></v-spacer>
                            <hb-icon color="#637381">mdi-filter-variant</hb-icon>
                          </div>
                      </template>

                      <v-card width="300">
                        <v-card-text class="pa-4" style="overflow: auto;">
                            <div class="hb-text-light hb-default-font-size font-weight-medium">Transaction Type</div>
                                <v-checkbox
                                    id="charges-checkbox"
                                    name="charges_checkbox"
                                    v-model="chargesCheckbox"
                                    hide-details
                                    class="mx-0 pa-0 my-3"
                                >
                                    <template v-slot:label>
                                        <span class="hb-text-light text-capitalize">Charges</span>
                                    </template>
                                </v-checkbox>
                                <v-checkbox
                                    class="mx-0 pa-0 my-3"
                                    id="payments-checkbox"
                                    name="payments_checkbox"
                                    v-model="paymentsCheckbox"
                                    hide-details
                                >
                                    <template v-slot:label>
                                        <span class="hb-text-light text-capitalize">Payments</span>
                                    </template>
                                </v-checkbox>
                          <div class="hb-text-light hb-default-font-size font-weight-medium mt-4">Date Range</div>
                          <v-select
                            :items="dateRangeOptions"
                            background-color="white"
                            hide-details
                            single-line
                            label="Select"
                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'hb-elevation-large' }"
                            v-model="dateRange"
                            id="dateRange"
                            class="pt-0"
                          >
                          </v-select>
                          <span v-if="dateRange === 'Custom'">
                            <hb-date-picker
                              @click:clear="customStartDate = ''"
                              label="Start Date"
                              :clearable="true"
                              :solo="false"
                              data-vv-as="start_date"
                              v-model="customStartDate"
                            ></hb-date-picker>
                            <hb-date-picker
                              v-if="customStartDate.length > 0"
                              @click:clear="customEndDate = ''"
                              label="End Date"
                              :min="customStartDate"
                              :clearable="true"
                              :solo="false"
                              data-vv-as="end_date"
                              v-model="customEndDate"
                            ></hb-date-picker>
                          </span>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="px-4 py-3" style="background: linear-gradient(180deg, #fafbfc 0%, #ebeff2 100%);">
                          <hb-link color="tertiary" @click="resetFilters('bigReset')">Clear Filters</hb-link>
                          <v-spacer></v-spacer>
                          <hb-btn color="secondary" small @click="goFilter(dateRange, customStartDate, customEndDate, chargesCheckbox, paymentsCheckbox)" :disabled="(!chargesCheckbox && !paymentsCheckbox && dateRange.length === 0) || (dateRange === 'Custom' && (customStartDate.length === 0 || customEndDate.length === 0))">Done</hb-btn>
                        </v-card-actions>
                      </v-card>
                  </v-menu>
                </div>
                <div class="pl-2">
                    <hb-link @click="setView">{{!showDetailed ? 'Show Detailed View' : 'Hide Detailed View'}}</hb-link>
                </div>
                <v-spacer></v-spacer>
                <div class="pl-2">
                    <HbBtn @click="showDownloadView" icon tooltip="Download" mdi-code="mdi-download" />
                </div>
                <div class="pr-0 text-right">
                    <v-text-field
                        outlined
                        hide-details
                        single-line
                        dense
                        v-model="search"
                        prepend-inner-icon="mdi-magnify"
                        clearable
                        clear-icon="mdi-close-circle"
                        class="hb-text-field-custom vuetify-input-fieldset-fix"
                        label="Search Transaction History"
                    ></v-text-field>
                </div>
                <div class="pr-0 text-right" :class="{'pr-6' : showAside && $vuetify.breakpoint.width > 1259}">
                    
                </div>
              </v-col>
          </v-row>
        </div>

        <v-card class="mb-4" :class="{'mr-6' : showAside && $vuetify.breakpoint.width > 1259}" elevation="0">
        
            <v-data-table
                :headers="headers"
                :items="newLedger"
                :item-class="rowClass"
                class="hb-data-table hb-data-table-cursor-on"
                @click:row="filter"
                disable-pagination
                hide-default-footer
                :search="search"
                :loading="newLedgerIsLoading"
                loading-text="Loading Transaction History..."
                :custom-filter="filterSearching"
                :custom-sort="customSort"
                group-by="category"
                :mobile-breakpoint="320"
            >
                <template v-slot:group.header="{group, items, isOpen, toggle}">
                    <td class="header-td-height" v-if="group == 'future'"></td>
                    <td class="header-td-height" v-if="group == 'future'"></td>
                    <td class="px-0 header-td-height furture-charges" v-if="group == 'future'">
                        <span @click="toggle" id="futureClick">
                            <span v-if="isOpen">Hide</span><span v-else>Show</span> Future Charges ({{items.length}})
                            <v-icon color="#00848e" class="mr-0 pr-0" v-if="isOpen">mdi-menu-up</v-icon>
                            <v-icon color="#00848e" class="mr-0 pr-0" v-else>mdi-menu-down</v-icon>
                        </span>
                    </td>
                    <td class="header-td-height" v-if="group == 'future'"></td>
                    <td class="header-td-height" v-if="group == 'future'"></td>
                    <td class="header-td-height" v-if="group == 'future'"></td>
                    <td class="header-td-height" v-if="group == 'future'"></td>
                </template>
                <template v-slot:item.transaction_date="{ item }">
                    <div class="main-row">
                        <!-- main row -->
                        <div class="hb-transaction-row">
                            <span v-if="item.transaction_date" class="align-self-center">
                                {{ item.transaction_date | formatDateServices }}
                            </span>
                        </div>
                        <!-- sub row if single line is present -->
                         <!-- only show sub row if transaction_status === INVOICE -->
                        <div v-if="isInvoiceType(item.transaction_type) && item.meta_data?.Line">
                            <div class="hb-sub-transaction-row" v-if="isShowDetailView(item.row_guid) && item.meta_data?.Invoice">
                                INV - {{ item.meta_data.Invoice?.number }}
                            </div>
                        </div>
                        <!-- sub rows if multiple Lines are present -->
                        <div v-if="isInvoiceType(item.transaction_type) && item.meta_data?.Lines && isShowDetailView(item.row_guid)">
                            <div class="hb-sub-transaction-row">
                            </div>
                            <div class="hb-sub-transaction-row" v-for="(line, i) in item.meta_data?.Lines" :key="i">
                                <span v-if="item.transaction_status !== TRANSACTION_TYPES.PAYMENT" class="d-flex align-center">
                                    INV - {{ line.invoice_number }}
                                </span>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:item.transaction_desc="{ item }">
                    <div class="main-row">
                        <!-- main row -->
                        <div class="hb-transaction-row">
                            <div v-if="item.transaction_desc" class="align-self-center">
                                <div>
                                    <hb-icon :color="isVoidOrFailedPayment(item.transaction_type) ? '#FB4C4C' : '#306FB6'" :mdi-code="getTransactionIcon(item)" small></hb-icon>
                                    <hb-link :class="{ 'single-line':isInvoiceType(item.transaction_type) }" :color="(item.transaction_status === TRANSACTION_TYPES.PAST_DUE || isVoidOrFailedPayment(item.transaction_type)) ? 'destructive' : 'secondary'" @click="goDescription(item)">
                                        {{item.transaction_prefix ? item.transaction_prefix + ': ' : ''}} {{ item.transaction_desc }}
                                    </hb-link> 
                                    <div v-if="isInvoiceType(item.transaction_type)" class="hb-text-light">
                                        INV - {{ item.transaction_ref_number }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <!-- sub row if only line is present -->

                        <!-- sub row if multiple Lines are present -->
                        <div v-if="isShowDetailView(item.row_guid)">
                            <div v-if="isAllType(item.transaction_type)" class="hb-sub-transaction-row">
                                <hb-icon v-if="isPaymentType(item.transaction_type)" mdi-code="mdi-account-clock-outline pr-1" small color="#101318"></hb-icon>                                
                                {{ getPaymentType(item)}}
                            </div>
                            <template v-if="item.meta_data?.Lines">
                                <div class="hb-sub-transaction-row" v-for="(line, i) in item.meta_data.Lines" :key="i">
                                    <span class="d-flex align-center">                               
                                        {{ line.description }}
                                    </span>
                                </div>
                        </template>
                        </div>
                    </div>
                </template>
                <template v-slot:item.transaction_start_date="{ item }">
                    <div class="main-row">
                        <!-- main row -->
                        <div class="hb-transaction-row">
                            <span v-if="item.meta_data?.Invoice" class="align-self-center">
                                {{ item.transaction_start_date | formatDateServices}} - {{ item.transaction_end_date | formatDateServices}}
                            </span>
                        </div>
                        <!-- sub row if only line is present -->
                        <div v-if="item.meta_data?.Line">
                            <div class="hb-sub-transaction-row" v-if="isShowDetailView(item.row_guid) && item.meta_data?.Invoice">
                                Due: {{ item.meta_data.Invoice.due | formatDateServices}}
                            </div>
                        </div>
                        <!-- sub row if multiple Lines are present -->
                        <div v-if="item.meta_data?.Lines && isShowDetailView(item.row_guid)">
                            <div v-if="item.meta_data" class="hb-sub-transaction-row">
                            </div>
                            <div class="hb-sub-transaction-row" v-for="(line, i) in item.meta_data.Lines" :key="i">
                                <span class="d-flex align-center">                               
                                {{ line.period_start | formatDateServices}} - {{ line.period_end | formatDateServices}}
                                </span>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:item.transaction_total_amount="{ item }">
                    <div class="main-row">
                        <!-- main row -->
                        <div class="hb-transaction-row">
                            <span :class="isPaymentRed(item.transaction_status) ? 'hb-text-error' : ''" v-if="item.transaction_total_amount" class="align-self-center">
                                {{ item.transaction_total_amount | formatMoneyWithoutAbs }}
                            </span>
                        </div>                        
                        <!-- sub row if only line is present -->
                        <div v-if="item.meta_data?.Line">
                            <div class="hb-sub-transaction-row" v-if="isShowDetailView(item.row_guid) && item.meta_data?.Invoice">
                            </div>
                        </div>
                        <!-- sub row if multiple Lines are present -->
                        <div v-if="item.meta_data?.Lines && isShowDetailView(item.row_guid)">
                            <div v-if="item.meta_data" class="hb-sub-transaction-row">
                                Transaction ID: {{ item.transaction_id }}
                            </div>
                            <div class="hb-sub-transaction-row" v-for="(line, i) in item.meta_data?.Lines" :key="i">
                                <span class="d-flex align-center">                               
                                {{ line.total_amount | formatMoneyWithoutAbs }}
                                </span>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:item.running_balance="{ item }">
                    <div  class="main-row">
                        <!-- main row -->
                        <div class="hb-transaction-row">
                            <span v-if="item" class="align-self-center">
                                {{ item.running_balance | formatMoneyWithoutAbs }}
                            </span>
                        </div>
                        <!-- sub row if only line is present -->
                        <div v-if="item.meta_data?.Line">
                            <div class="hb-sub-transaction-row" v-if="isShowDetailView(item.row_guid) && item.meta_data.Invoice"> 
                            </div>
                        </div>
                        <!-- sub row if multiple Lines are present -->
                        <div v-if="item.meta_data?.Lines && isShowDetailView(item.row_guid)">
                            <div class="hb-sub-transaction-row">
                            </div>
                            <div class="hb-sub-transaction-row" v-for="(line, i) in item.meta_data.Lines" :key="i">
                                <span class="d-flex align-center">
                                </span>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:item.running_paid_through_date="{ item }">
                    <div class="main-row">
                        <!-- main row -->
                        <div class="hb-transaction-row">
                            <span v-if="item" class="align-self-center">
                                {{ item.running_paid_through_date | formatDateServices}}
                            </span>
                        </div>
                        <!-- sub row if only line is present -->
                        <div v-if="item.meta_data?.Line">
                            <div class="hb-sub-transaction-row-last" v-if="isShowDetailView(item.row_guid)">
                                <hb-status-general :status="item.transaction_status"></hb-status-general>
                            </div>
                        </div>
                        <!-- sub row if multiple Lines are present -->
                        <div v-if="item.meta_data?.Lines && isShowDetailView(item.row_guid)">                            
                            <div class="hb-sub-transaction-row">
                            </div>
                            <div class="hb-sub-transaction-row-last" v-for="(line, i) in item.meta_data.Lines" :key="i">
                                <div class="d-flex align-center">
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:item.actions="{ item }">
                    <div class="main-row">
                        <!-- main row -->
                        <div class="hb-transaction-row right-end">
                            <span v-if="item" class="align-self-center">
                                <HbIcon pointer @click="filter()" :mdi-code="showDetailed || openRows.includes(item.row_guid) ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
                            </span>
                        </div>
                        <!-- sub row if only line is present -->
                        <div>
                            <div class="hb-sub-transaction-row-last" v-if="item.meta_data?.Invoice && isShowDetailView(item.row_guid)">
                                <div class="d-flex align-center">
                                    <hb-link color="secondary" @click="goInvoice(item.meta_data.Invoice?.id)">
                                        View/Print
                                    </hb-link> 
                                </div>  
                            </div>
                            <div class="hb-sub-transaction-row-last" v-if="item.meta_data && isShowViewPrint(item.transaction_type) && isShowDetailView(item.row_guid)">
                                <div class="d-flex align-center">
                                    <hb-link color="secondary" @click="goDescription(item)">
                                        View/Print
                                    </hb-link> 
                                </div>  
                            </div>
                        </div>
                        <!-- sub row if multiple Lines are present -->
                        <div v-if="item.meta_data?.Lines && isShowDetailView(item.row_guid)">
                            <div class="hb-sub-transaction-row-last" v-for="(line, i) in item.meta_data.Lines" :key="i">
                                <div class="d-flex align-center">
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </v-data-table>
        </v-card>

        <edit-payment
            v-model="showView"
            v-if="showView"
            :payment_id="payment_id"
            @refetch="transactionUpdated"
            @close="showView = false"
            :modal="true"
        >
        </edit-payment>

        <display-invoice
            v-model="showInvoice"
            v-if="showInvoice"
            :invoice_id="invoice_id"
            :contact_id="contact.id || $route.params.contact_id"
            @saved="fetchLedger(mainView)"
            @refreshInvoice="setInvoice"
            :fromVDialog="true"
            @close="showInvoice = false"
            :modal="true"
        >
        </display-invoice>
        <hb-modal
            size="large"
            v-model="showDownload"
            title="Download Transaction History"
            >
            <template v-slot:subheader>Description for download history</template>
            <template v-slot:content>
                <div>
                    <hb-form 
                        label="Date Range" 
                        description="Select the date range you want to include in this export" 
                        required
                        full
                    >
                            <v-select
                            :items="dateRangeOptions"
                            background-color="white"
                            hide-details
                            single-line
                            label="Select"
                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'hb-elevation-large' }"
                            v-model="downloadFields.dateRangeDownload"
                            id="dateRangeDownload"
                            class="pt-0"
                          >
                            </v-select>
                            <span v-if="downloadFields.dateRangeDownload === 'Custom'">
                                <v-row>
                                    <v-col>
                                        <hb-date-picker
                                            @click:clear="downloadFields.customStartDateDownload = ''"
                                            label="Start Date"
                                            :clearable="true"
                                            :solo="false"
                                            data-vv-as="start_date"
                                            v-model="downloadFields.customStartDateDownload"
                                        ></hb-date-picker>
                                    </v-col>
                                    <v-col>
                                        <hb-date-picker
                                            :disabled="downloadFields.customStartDateDownload.length < 0"
                                            @click:clear="downloadFields.customEndDate = ''"
                                            label="End Date"
                                            :min="downloadFields.customStartDateDownload"
                                            :clearable="true"
                                            :solo="false"
                                            data-vv-as="end_date"
                                            v-model="downloadFields.customEndDateDownload"
                                        ></hb-date-picker>
                                    </v-col>
                                    </v-row>
                            </span>
                    </hb-form>
                    <hb-form 
                        label="File Fromat"
                        required
                        description="Select the file format for this export">
                            <template>
                                <v-row class="ma-0">
                                    <v-col class="pa-0 mb-n3">
                                        <hb-radio-group v-model="downloadFields.downloadFormatType">
                                            <hb-radio disabled="true" label=".PDF" value="pdf"></hb-radio>
                                            <hb-radio label=".XSLX - Excel" value="xlsx"></hb-radio>
                                        </hb-radio-group>
                                    </v-col>
                                </v-row>
                            </template>
                    </hb-form>
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn color="primary" @click="downloadTransactionHistory">Download</hb-btn>
            </template>
            </hb-modal>

    </div>

</template>

<script type="text/babel">
    import Modal from '../assets/Modal.vue';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import EditPayment from '../payments/EditPayment.vue';
    import ViewPayment from '../payments/ViewPayment.vue';
    import HbDatePicker from '../assets/HummingbirdDatepicker';
    import DisplayInvoice from '../invoices/DisplayInvoice.vue';
    import moment from 'moment';
    import { EventBus } from '../../EventBus.js';
    import { mapGetters } from 'vuex'
    import { debounce } from 'lodash';
    import TRANSACTION_TYPES from '@/constants/transaction_types.js';
    import SpaceSelectionDropdown from '../settings/spaceSelectionDropdown/spaceSelectionDropdown.vue';
    
    export default {
        name: 'ContactTransactionHistory',
        data() {
            return {
                showView:false,
                showInvoice:false,
                showDownload:false,
                invoice_id: null,
                payment_id: null,
                ledger:[],
                headers: [
                    { text: 'Date', value: 'transaction_date', groupable: false, width: 110 },
                    { text: 'Description', value: 'transaction_desc', groupable: false, width: 250 },
                    { text: 'Service Period', value: 'transaction_start_date', groupable: false, width: 200 },
                    { text: 'Amount', value: 'transaction_total_amount', groupable: false, width:50 },
                    { text: 'Balance', value: 'running_balance', groupable: false, width:50 },
                    { text: 'Paid Through', value: 'running_paid_through_date', groupable: false, width: 110 },
                    { text: "", value: "actions", align: "right", sortable: false, width: 20 }
                ],
                mainView: {
                  id: null,
                  Unit: {
                    number: 'Tenant',
                    type: 'tenant',
                  }
                },
                notification: false,
                notification_type: '',
                notification_message: '',
                showFilters: false,
                dateRangeOptions: ['All Time', 'Last 30 Days', 'Custom'],
                dateRange: 'All Time',
                downloadFields: {
                    dateRangeDownload: 'All Time',
                    customStartDateDownload: '',
                    customEndDateDownload: '',
                    downloadFormatType: 'xlsx',
                },
                customStartDate: '',
                customEndDate: '',
                setFilterText: '',
                showDetailed: false,
                newLedger: [],
                openRows: [],
                selectedFilters: [],
                chargesCheckbox: false,
                paymentsCheckbox: false,
                search: '',
                newLedgerIsLoading: false,
                autoClickedFuture: false
            }
        },
        components:{
            Modal,
            Status,
            HbDatePicker,
            DisplayInvoice,
            EditPayment,
            ViewPayment,
            SpaceSelectionDropdown

        },
        async created (){
            this.TRANSACTION_TYPES = TRANSACTION_TYPES;
            this.debouncedFetchLedger = debounce(this.fetchLedger, 300);
            if(this.propertyView){
                await this.debouncedFetchLedger();
                this.hideFutureCharges();
            }

            EventBus.$on('lease_payment_saved', this.transactionUpdated);
            EventBus.$on('invoice_saved', this.transactionUpdated);
            EventBus.$on('show_lease_payment', this.showPayment);
            EventBus.$on('payment_saved', this.transactionUpdated);
            EventBus.$on('payment_updated', this.transactionUpdated);
            EventBus.$on('show_invoice', this.showInvoiceView);
            EventBus.$on('show_download', this.showDownloadView);
            // this.$on('spaceSelectionChanged',this.filterDataOnSelectedSpace)
            if(this.$vuetify.breakpoint.xs) {
                this.headers = this.headers.map(ele => {
                if(ele.text.toLowerCase() == 'description') {
                    ele.width = 250;
                }
                return ele;
                });
            }
        },
        destroyed(){
            EventBus.$off('lease_payment_saved', this.transactionUpdated);
            EventBus.$off('invoice_saved', this.transactionUpdated);
            EventBus.$off('show_lease_payment', this.showPayment);
            EventBus.$off('payment_saved', this.transactionUpdated);
            EventBus.$off('payment_updated', this.transactionUpdated);
            EventBus.$off('show_invoice', this.showInvoiceView);
            EventBus.$off('show_download', this.showDownloadView);
        },
        filters:{

        },
        computed:{
            mainViewOptions(){
              var options = [];
              options.push({
                id: null,
                Unit: {
                  number: 'Tenant',
                  type: 'tenant',
                }
              });
              this.contactLeases.forEach(lease => {
                options.push({
                  id: lease.id,
                  end_date: lease.end_date,
                  Unit: {
                    number: lease.Unit.number,
                    type: lease.Unit.type,
                    unit_type_id:lease.Unit.unit_type_id
                  }
                });
              })
              return options;
            }
        },
        methods: {
            async filterDataOnSelectedSpace(mainView){
                console.log('invocation success filer',this.propertyView);
                this.mainView = mainView
                this.newLedger = [];
                if(true){
                    await this.resetFilters();
                    await this.debouncedFetchLedger(mainView);
                    this.hideFutureCharges();
                }
            },
            filterSearching (value, search, item) {
                return value != null &&
                search != null &&
                (
                    value.toString().toLocaleLowerCase().indexOf(search.toString().toLocaleLowerCase()) !== -1
                    || (value.transaction_desc && value.transaction_desc.toString().toLocaleLowerCase().indexOf(search.toString().toLocaleLowerCase()) !== -1)
                    || (value.text && value.text.toString().toLocaleLowerCase().indexOf(search.toString().toLocaleLowerCase()) !== -1)
                    || (item.transaction_date && (moment(item.transaction_date).format("MMM DD, YYYY")).toString().toLocaleLowerCase().indexOf(search.toString().toLocaleLowerCase()) !== -1)
                    || (item.transaction_total_amount && (Math.round(item.transaction_total_amount * 1e2) / 1e2).toFixed(2).toString().indexOf(search.toString()) !== -1)
                    || (item.running_balance && (Math.round(item.running_balance * 1e2) / 1e2).toFixed(2).toString().indexOf(search.toString()) !== -1)
                    || (item.running_paid_through_date && (Math.round(item.running_paid_through_date * 1e2) / 1e2).toFixed(2).toString().indexOf(search.toString()) !== -1)
                    || (item.invoices && item.invoices[0] && item.invoices[0].unit_nbr && item.invoices[0].unit_nbr.toString().toLocaleLowerCase().indexOf(search.toString().toLocaleLowerCase()) !== -1)
                )
            },
            isVoidOrFailedPayment(type){
                return [TRANSACTION_TYPES.PAYMENT_VOID, TRANSACTION_TYPES.AUTO_PAYMENT_FAILED].includes(type);
            },
            isAllType(type){
                return [TRANSACTION_TYPES.VOID, TRANSACTION_TYPES.WRITE_OFF, TRANSACTION_TYPES.REFUND, TRANSACTION_TYPES.PAYMENT, TRANSACTION_TYPES.INVOICE, TRANSACTION_TYPES.PAYMENT_VOID, TRANSACTION_TYPES.AUTO_PAYMENT_FAILED, TRANSACTION_TYPES.REVERSAL, TRANSACTION_TYPES.AUCTION_PAYMENT].includes(type);
            },
            isPaymentType(type){
                return [TRANSACTION_TYPES.VOID, TRANSACTION_TYPES.WRITE_OFF, TRANSACTION_TYPES.REFUND, TRANSACTION_TYPES.PAYMENT, TRANSACTION_TYPES.AUTO_PAYMENT_FAILED, TRANSACTION_TYPES.REVERSAL, TRANSACTION_TYPES.AUCTION_PAYMENT].includes(type)
            },
            isPaymentRed(type){
                return [TRANSACTION_TYPES.PAST_DUE, TRANSACTION_TYPES.AUTO_PAYMENT_FAILED].includes(type);
            },
            isShowViewPrint(type){
                return [TRANSACTION_TYPES.REFUND, TRANSACTION_TYPES.PAYMENT, TRANSACTION_TYPES.AUTO_PAYMENT_FAILED, TRANSACTION_TYPES.REVERSAL, TRANSACTION_TYPES.AUCTION_PAYMENT].includes(type);
            },
            isShowDetailView(row_guid){
                return this.showDetailed || this.openRows.includes(row_guid);
            },
            isInvoiceType(type){
                return type === TRANSACTION_TYPES.INVOICE;
            },
            isRowPayment(type){
                return [TRANSACTION_TYPES.PAYMENT, TRANSACTION_TYPES.AUCTION_PAYMENT, TRANSACTION_TYPES.PAYMENT_VOID].includes(type);
            },
            customSort(items, index, isDesc) {
                let value_index = index[1]
                let is_desc_index = isDesc[1]
                items.sort((a, b) => {
                    if (value_index === 'transaction_date') {
                        const dateA = new Date(a.transaction_date).getTime();
                        const dateB = new Date(b.transaction_date).getTime();
                        if (!is_desc_index) {

                            if (dateA > dateB) {
                                return -1;
                            }
                            if (dateA < dateB) {
                                return 1;
                            }
                            return 0;
                        } else {

                            if (dateA < dateB) {
                                return -1;
                            }
                            if (dateA > dateB) {
                                return 1;
                            }
                            return 0;
                        }
                    }
                    if (value_index === 'transaction_start_date') {
                        const dateA = new Date(a.transaction_start_date).getTime();
                        const dateB = new Date(b.transaction_start_date).getTime();
                        if (!is_desc_index) {

                            if (dateA > dateB) {
                                return -1;
                            }
                            if (dateA < dateB) {
                                return 1;
                            }
                            return 0;
                        } else {

                            if (dateA < dateB) {
                                return -1;
                            }
                            if (dateA > dateB) {
                                return 1;
                            }
                            return 0;
                        }
                    }
                    if (value_index === 'running_paid_through_date') {
                        const dateA = new Date(a.running_paid_through_date).getTime();
                        const dateB = new Date(b.running_paid_through_date).getTime();
                        if (!is_desc_index) {

                            if (dateA > dateB) {
                                return -1;
                            }
                            if (dateA < dateB) {
                                return 1;
                            }
                            return 0;
                        } else {

                            if (dateA < dateB) {
                                return -1;
                            }
                            if (dateA > dateB) {
                                return 1;
                            }
                            return 0;
                        }
                    }
                    if (value_index === 'transaction_desc') {
                        let fa = `${a.transaction_prefix ? a.transaction_prefix.toLowerCase() + ': ' : ''}${a.transaction_desc.toLowerCase()}`,
                            fb = `${b.transaction_prefix ? b.transaction_prefix.toLowerCase() + ': ' : ''}${b.transaction_desc.toLowerCase()}`;

                        if (!is_desc_index) {

                            if (fa > fb) {
                                return -1;
                            }
                            if (fa < fb) {
                                return 1;
                            }
                            return 0;
                        } else {

                            if (fa < fb) {
                                return -1;
                            }
                            if (fa > fb) {
                                return 1;
                            }
                            return 0;
                        }
                    }
                    if (value_index === 'transaction_total_amount') {
                        let fa = a.transaction_total_amount;
                        let fb = b.transaction_total_amount;
                        if (!is_desc_index) {
                            if (fa > fb) {
                                return -1;
                            }
                            if (fa < fb) {
                                return 1;
                            }
                            return 0;
                        } else {
                            if (fa < fb) {
                                return -1;
                            }
                            if (fa > fb) {
                                return 1;
                            }
                            return 0;
                        }
                    }
                    if (value_index === 'running_balance') {
                        if (!is_desc_index) {

                            if (a.running_balance > b.running_balance) {
                                return -1;
                            }
                            if (a.running_balance < b.running_balance) {
                                return 1;
                            }
                            return 0;
                        } else {

                            if (a.running_balance < b.running_balance) {
                                return -1;
                            }
                            if (a.running_balance > b.running_balance) {
                                return 1;
                            }
                            return 0;
                        }
                    }
                });

                return items;
            },
            setInvoice (invoice_id) {
                this.invoice_id = invoice_id;
                this.transactionUpdated();
            },
            /*
            async fetchData(lease){
                api.get(this, api.LEASES + lease.id + '/ledger').then(r => {
                    this.ledger = r.ledger;
                });

            },
            */
            goDescription(item){
                if(item.transaction_type === TRANSACTION_TYPES.PAYMENT || item.transaction_type === TRANSACTION_TYPES.AUCTION_PAYMENT || item.transaction_type === TRANSACTION_TYPES.PAYMENT_VOID){
                    item.meta_data?.Payment && this.showPayment(item.meta_data.Payment.id);
                } else if(item.transaction_type === TRANSACTION_TYPES.INVOICE || item.transaction_type === TRANSACTION_TYPES.VOID){
                    this.goInvoice(item.meta_data.Invoice.id);
                } else if(item.transaction_type === TRANSACTION_TYPES.WRITE_OFF){
                    this.goInvoice(item.transaction_id);
                } else if(item.transaction_type === TRANSACTION_TYPES.REFUND){
                    item.meta_data?.Refund && this.showPayment(item.meta_data.Refund.payment_id);
                }
            },
            filter(item){
                if(item || (item.meta_data?.Invoice) || (item.lines?.length > 0)){
                    if(this.openRows.indexOf(item.row_guid) >= 0 ){
                        this.openRows.splice(this.openRows.indexOf(item.row_guid), 1);
                    } else {
                        this.openRows.push(item.row_guid);
                    }
                }
            },
            showPayment(id) {
                this.payment_id = id;
                this.showInvoice = false;
                this.showView = true;
            },
            goInvoice(id) {
                this.invoice_id = id;
                this.showInvoice = true;
            },
            async resetFilters(bigReset){
              this.setFilterText = '';
              this.chargesCheckbox = false;
              this.paymentsCheckbox = false;
              this.dateRange = 'All Time';
              this.customStartDate = '';
              this.customEndDate = '';
              this.showFilters = false;
              if(bigReset === 'bigReset') {
                  await this.fetchLedger(this.mainView);
                  this.hideFutureCharges();
              }
            },

            async goFilter(dateRange, customStartDate, customEndDate, chargesCheckbox, paymentsCheckbox){
                if((chargesCheckbox || paymentsCheckbox) && dateRange !== 'All Time'){
                    this.setFilterText = 'Custom';
                } else if (chargesCheckbox && paymentsCheckbox){
                    this.setFilterText = 'All'
                } else if (chargesCheckbox && !paymentsCheckbox && dateRange === 'All Time'){
                    this.setFilterText = 'Charges';
                } else if (!chargesCheckbox && paymentsCheckbox && dateRange === 'All Time'){
                    this.setFilterText = 'Payments';
                } else if (!chargesCheckbox || !paymentsCheckbox && dateRange.length > 0 && dateRange !== 'All Time'){
                    this.setFilterText = dateRange;
                }
                if(dateRange !== 'Custom'){
                    this.customStartDate = '';
                    this.customEndDate = '';
                }
                this.showFilters = false,
                await this.fetchLedger(this.mainView, dateRange, customStartDate, customEndDate, chargesCheckbox, paymentsCheckbox);
                this.hideFutureCharges();
            },

            async fetchLedger(lease, dateRange, customStartDate, customEndDate, chargesCheckbox, paymentsCheckbox){
                var selectedContact = '';
                if(this.contact && this.contact.id){
                    selectedContact = this.contact.id;
                } else {
                    selectedContact = this.$route.params.contact_id;
                }
                this.newLedger = [];
                this.newLedgerIsLoading = true;
                var ledger = [];
                if((!lease || lease.id === null || !lease.id) && this.propertyView){
                    if(dateRange === 'Last 30 Days'){
                        let r =  await api.get(this, api.TRANSACTION_HISTORY, {contact_id: selectedContact, property_id: this.propertyView, start_dt: moment().startOf('day').subtract(30, 'days').format('YYYY-MM-DD'), end_dt: moment().startOf('day').format('YYYY-MM-DD')});
                        ledger = r?.transactions.tenant_ledger;
                    } else if(dateRange === 'Custom'){
                        let r =  await api.get(this, api.TRANSACTION_HISTORY, {contact_id: selectedContact, property_id: this.propertyView, start_dt: customStartDate, end_dt: customEndDate});
                        ledger = r?.transactions.tenant_ledger;
                    } else {
                        let r =  await api.get(this, api.TRANSACTION_HISTORY, {contact_id: selectedContact, property_id: this.propertyView});
                        ledger = r?.transactions.tenant_ledger;
                    }
                } else if(this.propertyView) {
                    if(dateRange === 'Last 30 Days'){
                        let r =  await api.get(this, api.TRANSACTION_HISTORY, {lease_id: lease.id, contact_id: selectedContact, property_id: this.propertyView, start_dt: moment().startOf('day').subtract(30, 'days').format('YYYY-MM-DD'), end_dt: moment().startOf('day').format('YYYY-MM-DD')});
                        ledger = r?.transactions.tenant_ledger;
                    } else if(dateRange === 'Custom'){
                        let r =  await api.get(this, api.TRANSACTION_HISTORY, {lease_id: lease.id, contact_id: selectedContact, property_id: this.propertyView, start_dt: customStartDate, end_dt: customEndDate});
                        ledger = r?.transactions.tenant_ledger;
                    } else {
                        let r =  await api.get(this, api.TRANSACTION_HISTORY, {lease_id: lease.id, contact_id: selectedContact, property_id: this.propertyView});
                        ledger = r?.transactions.tenant_ledger;
                    }
                }
                if(chargesCheckbox && !paymentsCheckbox){
                    this.newLedger = ledger.filter(l => 
                        l.transaction_type === TRANSACTION_TYPES.INVOICE || l.transaction_type === TRANSACTION_TYPES.WRITE_OFF || l.transaction_type === TRANSACTION_TYPES.VOID
                    )
                } else if(paymentsCheckbox && !chargesCheckbox){
                    this.newLedger = ledger.filter(l => 
                        l.transaction_type === TRANSACTION_TYPES.PAYMENT || l.transaction_type === TRANSACTION_TYPES.AUCTION_PAYMENT || l.transaction_type === TRANSACTION_TYPES.REFUND || l.transaction_type === TRANSACTION_TYPES.REVERSAL || l.transaction_type === TRANSACTION_TYPES.PAYMENT_VOID || l.transaction_type === TRANSACTION_TYPES.AUTO_PAYMENT_FAILED
                    )
                } else {
                    this.newLedger = ledger;
                }
                this.newLedgerIsLoading = false;
            },
            hideFutureCharges() {
                if(document.getElementById("futureClick") && !this.newLedgerIsLoading && !this.autoClickedFuture) {
                    document.getElementById("futureClick").click();
                    this.autoClickedFuture = true;
                }
            },
            rowClass(item){
                if (item.transaction_type === TRANSACTION_TYPES.INVOICE) {
                    if(item.category == 'future') return "row-future-invoice";
                    return "row-simple";
                } else if (item.transaction_type === TRANSACTION_TYPES.PAYMENT || item.transaction_type === TRANSACTION_TYPES.AUCTION_PAYMENT  || item.transaction_type === TRANSACTION_TYPES.PAYMENT_VOID) {
                    return "row-payment";
                } else if (item.transaction_type === TRANSACTION_TYPES.REFUND || item.transaction_type === TRANSACTION_TYPES.REVERSAL || item.transaction_type === TRANSACTION_TYPES.AUTO_PAYMENT_FAILED) {
                    return "row-refund";
                } else if (item.transaction_type === TRANSACTION_TYPES.WRITE_OFF || item.transaction_type === TRANSACTION_TYPES.VOID) {
                    if(item.category == 'future') return "row-future-void";
                    return "row-void";
                }
                return "row-simple";
            },
            getTransactionIcon(item){
                if (item.transaction_type === TRANSACTION_TYPES.INVOICE) {
                    return "";
                } else if (item.transaction_type === TRANSACTION_TYPES.PAYMENT || item.transaction_type === TRANSACTION_TYPES.AUCTION_PAYMENT) {
                    return "mdi-credit-card-outline";
                } else if (item.transaction_type === TRANSACTION_TYPES.REFUND || item.transaction_type === TRANSACTION_TYPES.REVERSAL || item.transaction_type === TRANSACTION_TYPES.PAYMENT_VOID || item.transaction_type === TRANSACTION_TYPES.AUTO_PAYMENT_FAILED) {
                    return "mdi-cash-refund";
                } else if (item.transaction_type === TRANSACTION_TYPES.VOID) {
                    if(item.category == 'future') {
                        return "mdi-cash-plus";
                    }
                    return "mdi-credit-card-off-outline";
                }
                else if (item.transaction_type === TRANSACTION_TYPES.WRITE_OFF){
                    return "mdi-currency-usd-off"
                }
            },
            getPaymentType(item) {
                let toBeReturned = '';
                if (item.transaction_type === TRANSACTION_TYPES.REFUND && item.meta_data?.Refund) {
                    toBeReturned += `Refunded: ${this.$options.filters.formatDateTimeCustom(item.meta_data.Refund.created_at, 'MMM DD, YYYY [@] h:mma')} by ${item.meta_data.Refund.refund_to_name}`;
                } 
                if (item.transaction_type === TRANSACTION_TYPES.VOID && item.meta_data?.Invoice) {
                    toBeReturned += `Voided: ${this.$options.filters.formatDateTimeCustom(item.meta_data.Invoice.voided_at, 'MMM DD, YYYY [@] h:mma')} by ${item.meta_data.Invoice.voided_by_name}`;
                }
                if (item.transaction_type === TRANSACTION_TYPES.WRITE_OFF && item.meta_data?.Invoice) {
                    toBeReturned += `Write-Off: ${this.$options.filters.formatDateTimeCustom(item.meta_data.Invoice.created_at, 'MMM DD, YYYY [@] h:mma')} by ${item.meta_data.Invoice.created_by_name}`;
                } 
                if ((item.transaction_type === TRANSACTION_TYPES.PAYMENT || item.transaction_type === TRANSACTION_TYPES.PAYMENT_VOID || item.transaction_type === TRANSACTION_TYPES.AUTO_PAYMENT_FAILED) && item.meta_data?.Payment) {
                    toBeReturned += `Paid: ${this.$options.filters.formatDateTimeCustom(item.meta_data.Payment.created, 'MMM DD, YYYY [@] h:mma')} by ${item.meta_data.Payment.accepted_by_name}`;
                }   
                if (item.transaction_type === TRANSACTION_TYPES.INVOICE && item.meta_data?.Invoice) {
                    toBeReturned += `Created: ${this.$options.filters.formatDateTimeCustom(item.created_datetime, 'MMM DD, YYYY [@] h:mma')}`;
                }
                return toBeReturned;
            },


            setView(){
                this.openRows = [];
                this.showDetailed = !this.showDetailed;               
            },
            checkIfSameUnits(invoices){
                var sameUnitChecker = '';
                if(this.mainView.Unit.type === 'tenant'){
                    sameUnitChecker = invoices.every(i => i.unit_nbr.toLowerCase() === invoices[0].unit_nbr.toLowerCase());
                } else {
                    sameUnitChecker = invoices.every(i => i.unit_nbr.toLowerCase() === this.mainView.Unit.number.toLowerCase());
                }
                return sameUnitChecker;
            },
            transactionUpdated(){
                this.newLedgerIsLoading = true;
                this.newLedger = [];
                setTimeout(() => {
                    this.fetchLedger(this.mainView);
                    this.hideFutureCharges();
                }, 6000);
            },
            showInvoiceView(invoice_id) {
                this.invoice_id = invoice_id;
                this.showView = false;
                this.showInvoice = true;
            },
            showDownloadView() {
                this.showView = false;
                this.showInvoice = false;
                this.showDownload = true;
            },
            async downloadTransactionHistory() {
                this.showDownload = false;
                const {
                    dateRangeDownload,
                    customStartDateDownload,
                    customEndDateDownload,
                } = this.downloadFields;
                let selectedContact = "";
                if (this.contact && this.contact.id) {
                    selectedContact = this.contact.id;
                } else {
                    selectedContact = this.$route.params.contact_id;
                }
                try {
                    let data = null;
                    if (dateRangeDownload === "Last 30 Days") {
                        data = {
                            contact_id: selectedContact,
                            property_id: this.propertyView,
                            start_dt: moment()
                                .startOf("day")
                                .subtract(30, "days")
                                .format("YYYY-MM-DD"),
                            end_dt: moment().startOf("day").format("YYYY-MM-DD"),
                        };
                    } else if (dateRangeDownload === "Custom") {
                        data = {
                            contact_id: selectedContact,
                            property_id: this.propertyView,
                            start_dt: customStartDateDownload,
                            end_dt: customEndDateDownload,
                        };
                    } else {
                        data = {
                            contact_id: selectedContact,
                            property_id: this.propertyView,
                        };
                    }
                    let response = await this.$http.post(api.DOWNLOAD_TRANSACTION_HISTORY , data, { responseType: 'arraybuffer' });
                    let blob = new Blob([response.data], {
                        type: response.headers.get("content-type")
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `Transaction History ${dateRangeDownload}`;
                    link.click();
                }
                catch(err){
                    console.error(err);
                }
            },
            isLeaseEnded(lease) {
              if(lease.end_date?.length) {
                return true;
              }

              return false;
            },
            leaseSelectionColor(lease) {
              return this.isLeaseEnded(lease) ? '#637381' : '#101318';
            },
            leaseSelectionClass(lease) {
              return this.isLeaseEnded(lease) ? 'hb-text-night-light' : 'hb-text-night';
            }
        },
        props: ['contact', 'showAside', 'contactLeases', 'propertyView'],
        watch: {
            /*
            async contactLeases(){
                if(this.propertyView){
                    await this.resetFilters();
                    await this.fetchLedger(this.mainView);
                }
            },
            */
            async mainView(){
                this.newLedger = [];
                if(this.propertyView){
                    await this.resetFilters();
                    await this.debouncedFetchLedger(this.mainView);
                    this.hideFutureCharges();
                }
            },
            customStartDate(){
              if(this.customStartDate === ''){
                this.customEndDate = '';
              }
            },
            async propertyView(){
                if(this.mainView.Unit.type !== 'tenant'){
                    this.mainView = {
                        id: null,
                        Unit: {
                        number: 'Tenant',
                        type: 'tenant',
                        }
                    };
                } else {
                    await this.resetFilters();
                    await this.fetchLedger(this.mainView);
                    this.hideFutureCharges();
                }
            }
        }

    }
</script>

<style lang="scss">

#transaction-history {
    table {
        thead {
            tr {
                th {
                    padding-right: 0;
                }
            }
        }
        tbody {
            tr {
                td {
                    overflow-wrap: break-word;
                }
            }
            tr.row-payment {
                background-color: #f4f6f8;
                background-image: url('/img/green.png');
                background-repeat: no-repeat;
                background-size: 100% 3rem;   
            }
            tr.row-invoice {
                background-color: #f4f6f8;
                background-image: url('/img/invoice.png');
                background-repeat: no-repeat;
                background-size: 100% 3rem;   
            }
            tr.row-future-invoice {
                background-color: #f4f6f8;
                background-image: url('/img/invoice-hover.png');
                background-repeat: no-repeat;
                background-size: 100% 3rem;   
            }
            tr.row-refund {
                background-color: #f4f6f8;
                background-image: url('/img/refund.png');
                background-repeat: no-repeat;
                background-size: 100% 3rem;   
            }
            tr.row-void {
                background-color: #f4f6f8;
                background-image: url('/img/void.png');
                background-repeat: no-repeat;
                background-size: 100% 3rem;   
            }
            tr.row-simple {
                background-color: #f4f6f8;
                background-image: url('/img/invoice.png');
                background-repeat: no-repeat;
                background-size: 100% 3rem;   
            }
            tr.row-future-void {
                background-color: #f4f6f8;
                background-image: url('/img/void-hover.png');
                background-repeat: no-repeat;
                background-size: 100% 3rem;   
            }
        }
        tbody {
            tr.row-payment:hover {
                background-color: #f0fff1 !important;
                background-image: url('/img/void-hover.png') !important;
                background-repeat: no-repeat !important;
                background-size: 100% 3rem !important;
            }
            tr.row-invoice:hover {
                background-color: #fcfcfc !important;
                background-image: url('/img/void-hover.png') !important;
                background-repeat: no-repeat !important;
                background-size: 100% 3rem !important;
            }
            tr.row-future-invoice:hover {
                background-color: #F9FAFB !important;
                background-image: url('/img/invoice-hover.png') !important;
                background-repeat: no-repeat !important;
                background-size: 100% 3rem !important;   
            }
            tr.row-refund:hover {
                background-color: #f0fff1 !important;
                background-image: url('/img/void-hover.png') !important;
                background-repeat: no-repeat !important;
                background-size: 100% 3rem !important;
            }
            tr.row-void:hover {
                background-color: #fcfcfc !important;
                background-image: url('/img/void-hover.png') !important;
                background-repeat: no-repeat !important;
                background-size: 100% 3rem !important;   
            }
            tr.row-simple:hover {
                background-color: #eee !important;
                background-image: url('/img/void-hover.png') !important;
                background-repeat: no-repeat !important;
                background-size: 100% 3rem !important;   
            }
            tr.row-future-void:hover {
                background-color: #F9FAFB !important;
                background-image: url('/img/void-hover.png') !important;
                background-repeat: no-repeat !important;
                background-size: 100% 3rem !important;   
            }
        }
    }
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    height: 3rem;
    padding-top: 0.3em;
}
.header-td-height {
    height: 32px !important;
}
.furture-charges {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: #00848e;
}
.select-filter {
    border: 1px solid #DFE3E8;
    border-radius:4px;
    width:175px;
    height: 40px;
    background:white;
}

.select-filter:hover {
    border: 1px solid #C4CDD5;
    cursor: pointer;
}

.select-filter.download-center-filters-active {
    border: 2px solid #00848E;
}

.hb-transaction-row {
    padding: .5rem 0;
    height: 2.5rem;
    display: flex;
}

.hb-sub-transaction-row {
    height: 2rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.align-self-center {
    align-self: center;
}

.hb-sub-transaction-row-last {
    display: flex;
    align-items: center;
    height: 2rem;
}

.hb-sub-transaction-row:nth-child(1) {
    display: flex;
    align-items: center;
    height: 3rem;
}
.hb-sub-transaction-row-last:nth-child(1) {
    display: flex;
    align-items: center;
    height: 3rem;
}
.simple-row {
    height: 2.5rem;
}

.hb-text-field-custom {
    width: 280px;
}
.custom-flex-gap {
    //flex-direction: column;
    gap: 10px;

    flex-wrap: wrap
}

.gap-2 {
    gap: 8px;
}
.main-row {
    height:100%;
    display:flex;
    flex-direction: column;
}

.width-5 {
    width: 5vw;
}

.right-end {
    justify-content: flex-end;
}
.single-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}
.negative-margin-right {
    margin-right: -1vw;
}
</style>


